import { FC } from "react";
import "../styles.css";

interface IIntroView {
  handleClickStart: () => void;
}

const IntroView: FC<IIntroView> = (props) => {
  const { handleClickStart } = props;
  return (
    <div className="centered">
      <span className="title">ELEGIR COLORES!</span>
      <br />
      <br />
      <button className="button" onClick={handleClickStart}>
        A JUGAR!
      </button>
    </div>
  );
};

export default IntroView;
