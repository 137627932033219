import { FC } from "react";
import "../styles.css";

interface IResultsView {
  points: number;
  handleClickStart: () => void;
}

const ResultsView: FC<IResultsView> = (props) => {
  const { points, handleClickStart } = props;
  return (
    <div className="centered">
      <span className="title">GOOD GAME!</span>
      <br />
      <br />
      <span className="title">PUNTOS: {points}</span>
      <br />
      <br />
      <button className="button" onClick={handleClickStart}>
        JUGAR DE NUEVO
      </button>
    </div>
  );
};

export default ResultsView;
