export const colors = {
  green: "VERD",
  blue: "BLAU",
  red: "VERMELL",
  yellow: "GROC",
  purple: "MORAT",
  orange: "TARONJA",
  pink: "ROSA",
  brown: "MARRÓ",
  black: "NEGRE",
  white: "BLANC",
  gray: "GRIS",
};

export const getRandomKey = (): keyof typeof colors => {
  const keys = Object.keys(colors);
  return keys[Math.floor(Math.random() * keys.length)] as keyof typeof colors;
};

export const getKeys = (): (keyof typeof colors)[] => {
  const keys = Object.keys(colors);

  // select one at random to be correct
  const correctKey = getRandomKey();

  // get random key of all remaining keys to be incorrect
  const clone = [...keys];
  clone.splice(clone.indexOf(correctKey), 1);
  const incorrectKey = clone[
    Math.floor(Math.random() * clone.length)
  ] as keyof typeof colors;

  return [correctKey, incorrectKey];
};

export const getRandomValue = (): 0 | 1 => {
  return Math.round(Math.random()) as 0 | 1;
};
