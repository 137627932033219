import { useState } from "react";
import "./styles.css";
import GameView from "./views/Game";
import IntroView from "./views/Intro";
import ResultsView from "./views/Results";

function App() {
  // overall game state
  const [inGame, setInGame] = useState(false);
  const [points, setPoints] = useState<number | null>(null);

  const handleClickStart = () => {
    setInGame(true);
    setPoints(0);
  };

  const addPoint = () => {
    setPoints((p) => (p || 0) + 1);
  };

  const loseGame = () => {
    setInGame(false);
  };

  return (
    <div className="wrapper">
      <div className="view">
        {inGame ? (
          <GameView
            points={points ?? 0}
            addPoint={addPoint}
            loseGame={loseGame}
          />
        ) : points !== null ? (
          <ResultsView
            points={points ?? 0}
            handleClickStart={handleClickStart}
          />
        ) : (
          <IntroView handleClickStart={handleClickStart} />
        )}
      </div>
    </div>
  );
}

export default App;
